export class User {
    Id: number;
    RoleId: number;
    Email: string;
    Confirmed: boolean;
    FirstName: string;
    LastName: string;
    Active: Number;
    ReceiveNoti: Number;
    ReceiveServiceNoti: Number;
    ReceiveDailyReportSummaryNoti: Number;
    AuthKey: string;
    ParkIds: number[];
    RoleIds: any[];
    Roles: any[];
    CoContactPerson: number;
    FileUrl: string;
    Image: string;

    constructor(params = {}) {
        this.Id = params['Id'] || 0;
        this.RoleId = params['RoleId'] || 1;
        this.Confirmed = params['Confirmed'] || false;
        this.Email = params['Email'] ? params['Email'].trim() : '';
        this.AuthKey = params['AuthKey'] || '';
        this.FirstName = params['FirstName'] || '';
        this.LastName = params['LastName'] || '';
        this.CoContactPerson = params['CoContactPerson'] || 0;
        this.FileUrl = params['FileUrl'] || '';
        this.Image = params['Image'] || '';
        this.ParkIds = [];
        if (params['ParkIds']) {
            this.ParkIds = params['ParkIds'];
        }

        if (params['RoleIds']) {
            this.RoleIds = params['RoleIds'];
        }

        if (params['Roles']) {
            this.Roles = params['Roles'];
        }

        this.Active = params['Active'] ? 1 : 0;
        this.ReceiveNoti = params['ReceiveNoti'] ? 1 : 0;
        this.ReceiveServiceNoti = params['ReceiveServiceNoti'] ? 1 : 0;
        this.ReceiveDailyReportSummaryNoti = params['ReceiveDailyReportSummaryNoti'] ? 1 : 0;
    }
}
