
export class BeltServiceHistory {
    public Id: number;
    public DateAdded: string;
    public DateCompleted: string;
    public Belt: any;
    public Category: string;
    public BeltId: number;
    public CompletedByUser: { FileUrl: string, Name: string, Image: string };
    public Reporter: { FileUrl: string, Name: string, Image: string };
    constructor(
        params: {
            Id?: number,
            DateAdded?: string,
            DateCompleted?: string,
            Belt?: any,
            Category?: string,
            BeltId?: number,
            CompletedByUser?: { FileUrl: string, Name: string, Image: string },
            Reporter?: { FileUrl: string, Name: string, Image: string },
        }
    ) {
        this.Id = params['Id'] || 0;
        this.DateAdded = params['DateAdded'] || '';
        this.DateCompleted = params['DateCompleted'] || '';
        this.Belt = params['Belt'] || {};
        this.Category = params['Category'] || '';
        this.BeltId = params['BeltId'] || 0;
        this.CompletedByUser = params.CompletedByUser || null;
        this.Reporter = params.Reporter || null;
    }
}
