import { OnInit } from '@angular/core';
import { Data } from '@angular/router';
import { take } from 'rxjs/operators';

import { AppService } from '@app/core/service/app.service';

// @Injectable()
export class ComponentTitle implements OnInit {
    title = '';
    titleTranslateKey = '';
    contentTranslateKey = '';

    constructor(protected appSvc: AppService) {
    }

    ngOnInit() {
        // Set the html page (template) title from the route with the help of the `AppService`.
        this.appSvc
            .routeData$
            .pipe(
                take(1)
            )
            .subscribe((routeData: Data) => {
                this.title = routeData.title && routeData.title || '';
                this.titleTranslateKey = routeData.titleTranslateKey || '';
                this.contentTranslateKey = routeData.contentTranslateKey || '';
            });
    }
}
