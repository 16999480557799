export class CyComponent {
    Id: string;
    Name: string;
    ComponentTypeId: number;
    Description: string;
    Length: number;
    DeprecationPeriod: number;
    Deprecation: number;
    DateAdded: string;
    Parts: {Id: number, ComponentTypeCategory: 30, DateAdded: string}[];
    BelaySystemParts: {Id: number, ComponentTypeCategory: 30, DateAdded: string}[];
    RouteId: number;

    constructor(params) {
        this.Id = params.Id || '';
        this.Name = params.Name || '';
        this.ComponentTypeId = params.ComponentTypeId || 0;
        this.Description = params.Description || '';
        this.Length = params.Length || 0;
        this.DeprecationPeriod = params.DeprecationPeriod || 0;
        this.Deprecation = params.Deprecation || 0;
        this.DateAdded = params.DateAdded || '';
        this.Parts = params.Parts || [];
        this.BelaySystemParts = params.BelaySystemParts || [];
        this.RouteId = params.Route || 0;
    }
}
