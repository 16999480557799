import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable ,  throwError as _throw } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { UtilService } from './util.service';
import { AuthService } from './../auth/auth.service';
import { TokenService } from './../auth/token.service';


@Injectable()
export class ApiService {

    constructor(private httpClient: HttpClient,
                private utilSvc: UtilService,
                private authSvc: AuthService,
                private tokenSvc: TokenService) { }

    get(path: string,
        params: { [param: string]: string } = {}, forFile = false): Observable<any> {
        return this.httpClient.get<ApiResponse>(
            this.utilSvc.buildUrl(path),
            this.utilSvc.buildHttpRequestOptions(params, forFile)
        )
        // We need to use an arrow function for calling the `onError` function, because
        // if we call it directly ( `catch(this.onError(err))` ), then
        // the `this` reference is lost in the catch handler (`onError` function).
            .pipe(
                catchError((err: HttpErrorResponse) => this.onError(err)),
                map((resp: ApiResponse) => this.onSuccess(resp))
            );
    }

    post(path: string,
         body: {} = {},
         params: { [param: string]: string } = {}
        ): Observable<any> {
        return this.httpClient.post<ApiResponse>(
            this.utilSvc.buildUrl(path),
            JSON.stringify(body),
            this.utilSvc.buildHttpRequestOptions(params)
        ).pipe(
            catchError((err: HttpErrorResponse) => this.onError(err)),
            map((resp: ApiResponse) => this.onSuccess(resp))
        );
    }

    postFile(path: string,
        body: {} = {},
        params: { [param: string]: string } = {}
       ): Observable<any> {

       return this.httpClient.post<ApiResponse>(
           this.utilSvc.buildUrl(path),
           body,
           { headers: this.utilSvc.buildHttpHeadersForFile() }
       ).pipe(
           catchError((err: HttpErrorResponse) => this.onError(err)),
           map((resp: ApiResponse) => this.onSuccess(resp))
       );
   }

    checkTokenExpired(err: HttpErrorResponse): boolean {
        if (err && err.status && err.status === 401) {
            // Logout user.
            this.authSvc.logout(true);

            return true;
        }

        return false;
    }

    private onSuccess(resp: ApiResponse): Observable<ApiResponse> {
        // Replace old token to the new one if exists.
        if (resp.token) {
            this.tokenSvc.token = resp.token;
        }

        // NOTE\TODO: Maybe we can delete the `success` property from the response.
        return 'data' in resp && resp.data || resp.success || resp;
    }

    private onError(err: HttpErrorResponse): Observable<any> {

        // When token expired logout.
        this.checkTokenExpired(err);

        return _throw(err);
    }
}
