import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalizedDatePipe } from './localized-date.pipe';
import { ArrayPipe } from './array.pipe';
import { TruncatePipe } from './truncate.pipe';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule
  ],
  declarations: [
    LocalizedDatePipe,
    ArrayPipe,
    TruncatePipe,
  ],
  exports: [
    LocalizedDatePipe,
    ArrayPipe,
    TruncatePipe,
  ],
})
export class PipesModule { }
