import { FieldBase } from './field-base';

export class TextField extends FieldBase<string> {
    controlType = 'text';
    text: string;

    constructor(options: {} = {}) {
        super(options);
        this.text = options['text'] || '';
    }
}
