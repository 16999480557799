import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import { CustomDateFieldComponent } from './custom-date-field.component';
import { OwlDateTimeModule } from 'ng-pick-datetime';
import { FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    OwlDateTimeModule,
    NgxMaskModule.forRoot()
  ],
  declarations: [
    CustomDateFieldComponent
  ],
  exports: [
    CustomDateFieldComponent
  ]
})
export class CustomDateFieldModule {}
