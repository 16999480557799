import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EditorField } from '../field/field-editor';
import { EditorConfig } from './dynamic-editor.config';

@Component({
  selector: 'app-dynamic-editor',
  templateUrl: './dynamic-editor.component.html',
  styleUrls: ['./dynamic-editor.component.scss']
})
export class DynamicEditorComponent {
  @Input() field: EditorField;
  @Input() form: FormGroup;

  editorConfig = EditorConfig;
}
