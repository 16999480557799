export const MOMENT_FORMATS = Object.freeze({
    'nl': {
        longDateFormat : {
            LT : 'HH:mm',
            LTS : 'HH:mm:ss',
            L : 'DD-MM-YYYY',
            LL : 'DD-MM-YYYY',
            LLL : 'DD-MM-YYYY HH:mm',
            LLLL : 'dddd D MMMM YYYY HH:mm',
            l: 'MM-YYYY'
        }
    },
    'en-GB': {
        longDateFormat : {
            LT : 'HH:mm',
            LTS : 'HH:mm:ss',
            L : 'DD/MM/YYYY',
            LL : 'DD/MM/YYYY',
            LLL : 'DD/MM/YYYY HH:mm',
            LLLL : 'dddd D MMMM YYYY HH:mm',
            l: 'MM/YYYY'
        }
    },
    'de': {
        longDateFormat : {
            LT : 'HH:mm',
            LTS : 'HH:mm:ss',
            L : 'DD.MM.YYYY',
            LL : 'DD.MM.YYYY',
            LLL : 'DD.MM.YYYY HH:mm',
            LLLL : 'dddd D MMMM YYYY HH:mm',
            l: 'MM.YYYY'
        }
    },
    MY_NATIVE_FORMATS: {
        parseInput: 'DD/MM/YYYY',
        fullPickerInput: 'DD/MM/YYYY HH:mm',
        datePickerInput: 'DD/MM/YYYY',
        timePickerInput: 'HH:mm',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    }
});
