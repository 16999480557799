import {Routes} from '@angular/router';
import {Component, OnInit, ChangeDetectorRef, Input, AfterViewInit, ViewChild, TemplateRef} from '@angular/core';
import {FormGroup, AbstractControl, ValidatorFn, Validators} from '@angular/forms';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {
  FieldBase,
  TextboxField,
  TextareaField,
  SelectboxField,
  Select2boxField,
  DynamicFormComponent,
  DropzoneField,
  RadioboxField,
  DateTimeField,
  SliderField
} from '../../dynamic-form/index';
import {Report} from '../../model';
import {DynamicFormDzService} from '@app/core/dynamic-form/dynamic-form-dz-field/dynamic-form-dz-service';

import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';
import {TextField} from '@app/core/dynamic-form/field/field-text';

@Component({
  selector: 'app-dialog-report',
  templateUrl: './dialog-report.component.html'
})
export class DialogReportComponent implements OnInit, AfterViewInit {
  @ViewChild('avatarTpl')
  avatarTpl: TemplateRef<any>;
  @ViewChild('df') private df: DynamicFormComponent;
  @Input() elements: any[];
  @Input() inspectionAttributes;
  @Input() options;
  @Input() users: any[];
  @Input() settings: Settings;

  public form: FormGroup;
  public fields: FieldBase<any>[] = [];
  private importanceField: SliderField;
  private elementField: Select2boxField;
  private partsField: Select2boxField;
  private assignedField: Select2boxField;
  private routesField: Select2boxField;
  private locationField: Select2boxField;
  private inspectionTypesField: Select2boxField;
  private typeOfPartsRadioField: RadioboxField;
  private optionsForTypeOfPart;
  private descriptionField: TextareaField;
  saveDisable = true;
  mainTranslateKey: string;
  textField: TextField;

  constructor(
    private cdRef: ChangeDetectorRef,
    public dzFieldService: DynamicFormDzService,
    public activeModal: NgbActiveModal,
    public translate: TranslateService) {
  }

  ngOnInit() {
    this.mainTranslateKey = 'dialogReport';
    this.optionsForTypeOfPart = <any>[
      {Id: 'BelaySystemParts', Name: this.translate.instant(`${this.mainTranslateKey}.optionBelaySystem`)},
      {Id: 'Parts', Name: this.translate.instant(`${this.mainTranslateKey}.optionParts`)},
    ];

    this.buildFields();
  }

  ngAfterViewInit() {
    this.form = this.df.form;
    this.saveDisable = false;
    this.form.get('ComponentId').disable({emitEvent: false});

    // If elements does not have route or the route of element is active
    const filteredElements = this.elements.filter(element => {
      if (!element.RouteId) {
        return true;
      }

      if (element.RouteId && this.options.routes.find(i => i.Id === element.RouteId)) {
        return true;
      }

      return false;
    });

    this.onlyNecessaryRouteOptions(filteredElements);

    this.assignedField.addOptions(this.users);
    this.routesField.options = <any>this.options.routes;
    this.manageInspTypesField();

    this.form.get('RouteId').valueChanges.subscribe(val => {
      if (!val) {
        this.elementField.options = [];
        return;
      }

      this.elementField.options = _.filter(filteredElements, element => {
        return !element.RouteId || +element.RouteId === +val;
      });
      this.elementField.options = this.elementField.options.sort((a, b) => a.Name.localeCompare(b.Name));

      this.form.get('ComponentId').enable({emitEvent: false});
    });

    this.form.get('ComponentId').valueChanges.subscribe(val => {
      if (!val) {
        this.typeOfPartsRadioField.hidden = true;
        this.form.get('TypeOfPart').reset();
        this.textField.hidden = true;
        return;
      }
      const element = _.find(this.elements, {Id: val});

      if (element.Description) {
        this.textField.hidden = false;
        this.textField.text = 'Element description: ' + element.Description;
      } else {
        this.textField.hidden = true;
      }

      if (element.Parts && element.Parts.length && element.BelaySystemParts && element.BelaySystemParts.length) {
        this.typeOfPartsRadioField.hidden = false;
        this.form.get('TypeOfPart').reset();
        this.form.get('TypeOfPart').setValue('Parts');
        return;
      }

      if (element.Parts && element.Parts.length) {
        this.typeOfPartsRadioField.hidden = true;
        this.partsField.hidden = false;
        this.partsField.options = <any>element.Parts;
        return;
      }

      if (element.BelaySystemParts && element.BelaySystemParts.length) {
        this.typeOfPartsRadioField.hidden = true;
        this.partsField.hidden = false;
        this.partsField.options = <any>element.BelaySystemParts;
        return;
      }

      this.partsField.hidden = true;
      this.typeOfPartsRadioField.hidden = true;
      this.form.get('ComponentPartId').setValue('');
    });

    this.form.get('TypeOfPart').valueChanges.subscribe(val => {
      const idOfElement = this.form.get('ComponentId').value;
      const element = _.find(this.elements, {Id: idOfElement});
      if (!element) {
        this.partsField.hidden = true;
        this.partsField.options = [];
      } else {
        this.partsField.hidden = false;
        this.partsField.options = <any>element[val];
      }
      this.form.get('ComponentPartId').setValue('');
    });

    if (this.inspectionAttributes.routeId !== 0 && this.inspectionAttributes.routeInspectionId !== 0) {
      this.form.patchValue({
        RouteId: this.inspectionAttributes.routeId,
        InspectionTypeId: this.inspectionAttributes.routeInspectionId
      });
    }

    if (this.options.routes && this.options.routes.length === 1) {
      this.form.get('RouteId').setValue(this.options.routes[0].Id, {onlySelf: true});
    }

    if (filteredElements.length === 1 && filteredElements[0].RouteId) {
      this.form.get('ComponentId').enable();
      this.form.get('ComponentId').setValue(filteredElements[0].Id, {onlySelf: true});
    }

    this.manageLocationField();
    this.cdRef.detectChanges();
    this.importanceField.manualRefresh.emit();
  }

  private buildFields(): void {
    this.fields = [
      this.routesField = new Select2boxField({
        key: 'RouteId',
        label: this.translate.instant(`${this.mainTranslateKey}.labelRoute`),
        keyField: 'Id',
        valField: 'Name',
        styleClass: 'col-md-6',
        required: true
      }),
      this.inspectionTypesField = new Select2boxField({
        key: 'InspectionTypeId',
        label: this.translate.instant(`${this.mainTranslateKey}.labelInspectionType`),
        keyField: 'Id',
        valField: 'Name',
        styleClass: 'col-md-6',
        required: true
      }),
      this.elementField = new Select2boxField({
        key: 'ComponentId',
        label: this.translate.instant(`${this.mainTranslateKey}.labelElement`),
        keyField: 'Id',
        valField: 'Name',
        styleClass: this.options.showLocationField && 'col-md-6' || '',
      }),
      (this.textField = new TextField({
        key: 'elementDescription',
        text: '',
        hidden: true,
      })),
      this.typeOfPartsRadioField = new RadioboxField({
        key: 'TypeOfPart',
        label: this.translate.instant(`${this.mainTranslateKey}.labelTypeOfParts`),
        keyField: 'Id',
        valField: 'Name',
        options: this.optionsForTypeOfPart,
        hidden: true,
      }),
      this.partsField = new Select2boxField({
        key: 'ComponentPartId',
        label: this.translate.instant(`${this.mainTranslateKey}.labelParts`),
        keyField: 'Id',
        valField: 'Name',
        hidden: true,
      }),
      (this.descriptionField = new TextareaField({
        key: 'Description',
        label:  this.translate.instant(`${this.mainTranslateKey}.labelDescription`),
        maxLength: 255,
        required: true
      })),
      this.importanceField = new SliderField({
        key: 'ImportanceLevel',
        label: this.translate.instant(`${this.mainTranslateKey}.labelImportanceLevel`),
        floor: this.settings.MinImportanceLevel,
        ceil: this.settings.MaxImportanceLevel,
        showTicks: true,
        required: true,
        value: this.settings.MinImportanceLevel,
        getSelectionBarColor: (value: number): string => {
          return '#489774';
        },
      }),
      new DateTimeField({
        key: 'DueDate',
        label: this.translate.instant(`${this.mainTranslateKey}.labelDueDate`),
        type: 'date',
        pickerType: 'calendar',
        value: moment().add(1, 'day').format('YYYY-MM-DD'),
        validators: [this.dueDateValidator()],
        errorMessages: {
          dueDate: `The due date could not be in the past`
        },
        min: new Date(moment().format('YYYY-MM-DD') + ' 00:00'),
        styleClass: 'col-md-6',
      }),
      this.assignedField = new Select2boxField({
        key: 'AssignedId',
        label: this.translate.instant(`${this.mainTranslateKey}.labelAssigned`),
        keyField: 'Id',
        valField: 'Name',
        styleClass: 'col-md-6',
        avatar: true,
        avatarKey: 'Image'
      }),
      new DropzoneField({
        label: this.translate.instant(`${this.mainTranslateKey}.labelUploadFiles`),
        message: this.translate.instant('dropzone.uploadFilesPNG'),
        base64Mode: true,
        config: {
          paramName: 'File',
          acceptedFiles: 'image/jpeg,image/png,image/jpg',
          addRemoveLinks: true,
          dictRemoveFile: this.translate.instant('dropzone.removeFile'),
          url: 'uploadimage',
          autoProcessQueue: false
        }
      }),
    ];

    if (this.options.showLocationField) {
      this.fields.unshift(
        this.locationField = new Select2boxField({
          key: 'LocationId',
          label:  this.translate.instant(`${this.mainTranslateKey}.labelLocation`),
          options: this.options.parks,
          keyField: 'Id',
          valField: 'Name',
          styleClass: 'col-md-6',
        })
      );
    }
  }

  async saveForm() {
    if (!this.form.valid) {
      this.df.updateErrors(true);
      return;
    }

    const report = new Report(this.form.value);
    const el = this.elements.find(item => item.Id === report.ComponentId);
    report.Element = el && el.Name || '';
    const images = await this.dzFieldService.getFilesAsBase64();

    const inspectionAttributes = {
      routeId: this.form.value.RouteId,
      routeInspectionId: this.form.value.InspectionTypeId
    };

    report.Hash = Date.now().toString();

    // Close dialog and return new/edited item and parts
    this.activeModal.close(
      {
        report: report,
        images: images,
        inspectionAttributes: inspectionAttributes
      });
  }

  dueDateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const date = control.value;
      const today = moment().format('YYYY-MM-DD');
      if (!date) {
        return null;
      }
      if (moment(date).isSameOrAfter(today)) {
        return null;
      } else {
        return {'dueDate': {value: date}};
      }
    };
  }

  private onlyNecessaryRouteOptions(filteredElements: any[]) {
    if (filteredElements.some(el => !el.RouteId) || this.options.showLocationField) {
      return;
    }

    this.options.routes = _.filter(this.options.routes, r => filteredElements.some(el => el.RouteId === r.Id));
  }

  private manageInspTypesField() {
    const inspTypesField = this.inspectionTypesField,
      inspTypesControl = this.form.get(inspTypesField.key);
    inspTypesField.options = <any>this.options.inspectionTypes;

    if (inspTypesField.options.length === 1) {
      inspTypesControl.setValue(inspTypesField.options[0].Id);
    }
  }

  private manageLocationField() {
    if (!this.options.showLocationField) {
      return;
    }

    this.routesField.options = [];
    const locationOptions = this.locationField.options,
      locationControl = this.form.get('LocationId');

    locationControl.valueChanges.subscribe(value => {
      this.form.get('RouteId').setValue(null);

      if (!value) {
        this.routesField.options = [];
        return;
      }

      this.routesField.options = this.options.routes.filter(item => item.ParkId === value);
    });

    if (locationOptions.length === 1) {
      locationControl.setValue(locationOptions[0].Id);
    }
  }
}
