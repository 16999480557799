import * as moment from 'moment';
export class IncidentReportHandling {
    public Id: number;
    public IncidentReportId: number;
    public GuestOrEmployeeContacted: any;
    public AttentionDelivered: any;
    public MaterialOrRouteInvolvedChecked: number | boolean;
    public CieVNotification: any;
    public RIAndETested: any;
    public Notification: string;
    public MeasuresApplied: number | boolean;
    public PreventionPossible: number | boolean;
    public Relationship: string;
    public Measure: string;
    public RIAndEAdjusted: number | boolean;
    public ParkManagerId: number;
    public Date: string;
    public DateAdded: string;
    public DateUpdated: string;
    public ModifierUserId: number;

    constructor(
        report: {
            Id?: number,
            IncidentReportId?: number,
            GuestOrEmployeeContacted?: any,
            AttentionDelivered?: any,
            MaterialOrRouteInvolvedChecked?: number | boolean;
            CieVNotification?: any,
            RIAndETested?: any,
            Notification?: string,
            MeasuresApplied?: number | boolean,
            PreventionPossible?: number | boolean,
            Relationship?: string,
            Measure?: string,
            RIAndEAdjusted?: number | boolean,
            ParkManagerId?: number,
            Date?: string | Date,
            DateAdded?: string,
            DateUpdated?: string,
            ModifierUserId?: number
        }
    ) {
        this.Id = report.Id || 0;
        this.IncidentReportId = report.IncidentReportId || 0;
        this.GuestOrEmployeeContacted = report.GuestOrEmployeeContacted;
        this.AttentionDelivered = report.AttentionDelivered;
        this.MaterialOrRouteInvolvedChecked = report.MaterialOrRouteInvolvedChecked || 0;
        this.CieVNotification = report.CieVNotification;
        this.RIAndETested = report.RIAndETested;
        this.Notification = report.Notification || '';
        this.MeasuresApplied = report.MeasuresApplied || 0;
        this.PreventionPossible = report.PreventionPossible || 0;
        this.Relationship = report.Relationship || '';
        this.Measure = report.Measure || '';
        this.RIAndEAdjusted = report.RIAndEAdjusted || 0;
        this.ParkManagerId = report.ParkManagerId || null;
        this.Date = report.Date ? moment(report.Date).format('YYYY-MM-DD') : '';
        this.DateAdded = report.DateAdded || '';
        this.DateUpdated = report.DateUpdated || '';
        this.ModifierUserId = report.ModifierUserId || 0;
    }

    public prepareForClient() {
        this.GuestOrEmployeeContacted = this.getValueJaNeNvtBySide(this.GuestOrEmployeeContacted, false);
        this.AttentionDelivered = this.getValueJaNeNvtBySide(this.AttentionDelivered, false);
        this.CieVNotification = this.getValueJaNeNvtBySide(this.CieVNotification, false);
        this.RIAndETested = this.getValueJaNeNvtBySide(this.RIAndETested, false);
        this.MeasuresApplied = this.MeasuresApplied === 1 ? true : false;
        this.MaterialOrRouteInvolvedChecked = this.MaterialOrRouteInvolvedChecked === 1 ? true : false;
        this.PreventionPossible = this.PreventionPossible === 1 ? true : false;
        this.RIAndEAdjusted = this.RIAndEAdjusted === 1 ? true : false;
    }

    public prepareForServer() {
        this.GuestOrEmployeeContacted = this.getValueJaNeNvtBySide(this.GuestOrEmployeeContacted, true);
        this.AttentionDelivered = this.getValueJaNeNvtBySide(this.AttentionDelivered, true);
        this.CieVNotification = this.getValueJaNeNvtBySide(this.CieVNotification, true);
        this.RIAndETested = this.getValueJaNeNvtBySide(this.RIAndETested, true);
        this.MeasuresApplied = this.MeasuresApplied === true ? 1 : 0;
        this.PreventionPossible = this.PreventionPossible === true ? 1 : 0;
        this.MaterialOrRouteInvolvedChecked = this.MaterialOrRouteInvolvedChecked === true ? 1 : 0;
        this.RIAndEAdjusted = this.RIAndEAdjusted === true ? 1 : 0;
    }

    private getValueJaNeNvtBySide(value: any, isClient: boolean) {
      if (isClient) {
          switch (value) {
              case 'yes':
                  return 1;
              case 'no':
                  return 0;
              default:
                  return null;
          }
      } else {
          switch (value) {
              case 1:
                  return 'yes';
              case 0:
                  return 'no';
              default:
                  return 'n/a';
          }
      }
  }
}
