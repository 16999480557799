import * as moment from 'moment';
import { MaintenanceComment } from './belt/types';

export class RouteServiceEdit {
    public Id: number;
    public ImportanceLevel: number;
    public Description: string;
    public RepairDescription: string;
    public DueDate: string;
    public DateAdded: string;
    public Reporter: string;
    public DateCompleted: string;
    public FileUrl: any;
    public Photos: { Id: number, Name: string }[];
    public RepairPhotos: { Id: number, Name: string }[];
    public AssignedId: number;
    public AssignedTo: {Id: number, Name: string, FileUrl: any, Image: string}[];
    public Inspection: any;
    public ComponentPart: any;
    public ComponentName: string;
    public Comments: MaintenanceComment[];
    public LastComment: MaintenanceComment[];
    constructor(params: {
        Id?: number,
        ImportanceLevel?: number,
        Description?: string,
        RepairDescription?: string,
        DueDate?: string,
        DateAdded?: string,
        Reporter?: string,
        DateCompleted?: string,
        FileUrl?: string,
        Photos?: { Id: number, Name: string }[],
        RepairPhotos?: { Id: number, Name: string }[],
        AssignedId?: number,
        AssignedTo?: {Id: number, Name: string, FileUrl: any, Image: string}[];
        Inspection?: any,
        ComponentPart?: any,
        ComponentName?: string,
        Comments?: MaintenanceComment[],
        LastComment?: MaintenanceComment[];
    }
    ) {
        this.Id = params.Id || 0;
        this.ImportanceLevel = params.ImportanceLevel || 0;
        this.Description = params.Description || '';
        this.RepairDescription = params.RepairDescription || '';
        this.DueDate = params.DueDate || '';
        this.DateAdded = params.DateAdded ? moment(params.DateAdded).format('YYYY-MM-DD HH:mm') : '';
        this.Reporter = params.Reporter || '';
        this.DateCompleted = params.DateCompleted || '';
        this.FileUrl = params.FileUrl || {};
        this.Photos = params.Photos || [];
        this.RepairPhotos = params.RepairPhotos || [];
        this.AssignedId = params.AssignedId || null;
        this.AssignedTo = params.AssignedTo || [];
        this.Inspection = params.Inspection || {};
        this.ComponentPart = params.ComponentPart || {};
        this.ComponentName = params.ComponentName || '';
    }
}
