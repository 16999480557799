import { AngularEditorConfig } from "@kolkov/angular-editor";

export const EditorConfig: AngularEditorConfig = {
    editable: true,
    toolbarHiddenButtons: [
        [
          'undo',
          'redo',
          //'strikeThrough',
          'subscript',
          'superscript',
          'justifyLeft',
          'justifyCenter',
          'justifyRight',
          'justifyFull',
          'indent',
          'outdent',
          //'insertUnorderedList',
          //'insertOrderedList',
          'heading',
          'fontName'
        ],
        [
          'fontSize',
          'textColor',
          'backgroundColor',
          'customClasses',
          'link',
          'unlink',
          'insertImage',
          'insertVideo',
          'insertHorizontalRule',
          'removeFormat',
          'toggleEditorMode'
        ]
    ]
}