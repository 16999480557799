export class ActivityType {
    Id: number;
    Name: string;


    constructor(params = {}) {
        this.Id = params['Id'] || 0;
        this.Name = params['Name'] || '';
    }
}
