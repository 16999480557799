import { InjectionToken } from '@angular/core';

import {InspectionType} from '../../model/generals/generals';
import {ApiService, BaseFeatureModuleService} from '../index';

export const InspectionTypeService = new InjectionToken<BaseFeatureModuleService<InspectionType>>('InspectionType');

export function inspectionTypeSvcFactory(apiSvc: ApiService): BaseFeatureModuleService<InspectionType> {
  return new BaseFeatureModuleService<InspectionType>(
    apiSvc, 'inspection-type',
    {
      getItems: {
        expand: 'ApplicationScope'
      },
      getItem: {
        expand: 'Manuals,Description'
      }
    }
  );
}
