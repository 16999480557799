import { MOMENT_FORMATS } from '@app/core/config/time-zone.format';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '../service/settings.service';
import * as moment from 'moment';
import 'moment-timezone';
@Pipe({
  name: 'localizedDate'
})
export class LocalizedDatePipe implements PipeTransform {

  constructor(private translate: TranslateService,
    private settingsSvc: SettingsService) {
    moment.updateLocale('nl', MOMENT_FORMATS['nl']);
    moment.updateLocale('en-GB', MOMENT_FORMATS['en-GB']);
    moment.updateLocale('en', MOMENT_FORMATS['en-GB']);
    moment.updateLocale('de', MOMENT_FORMATS['de']);
  }

  transform(value: string, pattern: string = 'L'): any {
    moment.locale(this.translate.currentLang);
    const timezone = this.settingsSvc.getCompanySettsings().Timezone;
    // tslint:disable-next-line:curly
    if (!value) return '';
    let date = moment.utc(value).tz(timezone).format(pattern); // TODO: Dynamic from settings
    // let date = moment(value).format(pattern);
    if (date === 'Invalid date') {
      date = '';
    }
    return date;
  }

}
