import { InjectionToken } from '@angular/core';

import { BaseFeatureModuleService } from './base-feature-module-service';
import { ApiService } from './api.service';

import { BeltServiceHistory } from '../model';

export const ServiceBeltServiceHistory = new InjectionToken<BaseFeatureModuleService<BeltServiceHistory>>('BeltServiceHistory');

export function serviceBeltHistorySvcFactory(apiSvc: ApiService): BaseFeatureModuleService<BeltServiceHistory> {
    return new BaseFeatureModuleService<BeltServiceHistory>(apiSvc, 'belt/service/history',
    {
        getItems: {
            expand: 'Belt.Park,Category,Reporter,CompletedByUser',
            fields: 'Id,DateAdded,DateCompleted,Belt.Name,Belt.Barcode,BeltId'
        }
    }
);
}
