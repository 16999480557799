import { CompanySettings } from './../model/settings/company-settings.model';
import { Injectable } from '@angular/core';

import { Observable ,  of ,  Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';

import { Role, EquipmentType } from '../model';
import { SettingsProvider } from '../app-load/settings.provider';
import { LanguagesSettings } from '../model/settings/languages-settings.model';
import * as _ from 'lodash';
@Injectable()
export class SettingsService {
    private settings: Settings;

    public settingsUpdated: Subject<any> = new Subject();
    public settingsHasChangedSubject: Subject<any> = new Subject();

    private extendParams: string;

    constructor(
        private apiSvc: ApiService,
        private settingsProvider: SettingsProvider
    ) {
        // tslint:disable-next-line:max-line-length
        this.extendParams = 'Languages,Company,Modules,CoPermissions,UserPermissions,BeltStatuses,Periods,Roles,EquipmentTypes,RouteProblemsCnt';
    }

    get(isMust = false): Observable<Settings> {
        return this.settings && !isMust
            && of(this.settings)
            || this.apiSvc
                .get('settings', { expand: this.extendParams })
                .pipe(map((data: any) => this.settings = data));
    }

    getRoles(): Observable<Role[]> {
        if (this.settings && this.settings.Roles) {
            return of(this.settings.Roles);
        }
        return this.apiSvc
            .get('settings', { expand: this.extendParams })
            .pipe(map((data: any) => {
                this.settings = data;
                return this.settings.Roles;
            }));
    }

    getParkBGOnInspection(): boolean {
        return this.settings.ParkBGOnInspection;
    }

    getInterventionBeltCategoryId(): number {
      return this.settings.InterventionBeltCategoryId;
    }

    getEquipmentTypes(): Observable<EquipmentType[]> {
        if (this.settings && this.settings.EquipmentTypes) {
            return of(this.settings.EquipmentTypes);
        }

        return this.apiSvc
            .get('settings', { expand: this.extendParams })
            .pipe(map((data: any) => {
                this.settings = data;
                return this.settings.EquipmentTypes;
            }));
    }

    getHighestRole(): string {
        return this.settings.Company.HighestRole;
    }

    getRouteProblemsCnt(): number {
        return this.settings.RouteProblemsCnt;
    }

    getRouteProblemsCntFromApi(): Observable<any> {
        return this.apiSvc
                .get('settings', { expand: 'RouteProblemsCnt', fields: 'RouteProblemsCnt' })
                .pipe(map((data: any) => {
                    this.settings.RouteProblemsCnt = data.RouteProblemsCnt;
                    this.settingsHasChangedSubject.next();
                }));
    }

    getBeltStatuses(): Observable<BeltStatus[]> {
        if (this.settings && this.settings.BeltStatuses) {
            return of(this.settings.BeltStatuses);
        }

        return this.apiSvc
            .get('settings', { expand: this.extendParams })
            .pipe(map((data: any) => {
                this.settings = data;
                return this.settings.BeltStatuses;
            }));
    }

    getBeltServiceCheckTypes(): Observable<BeltStatus[]> {
      if (this.settings && this.settings.BeltServiceCheckTypes) {
        return of(this.settings.BeltServiceCheckTypes);
      }

      return this.apiSvc
        .get('settings', { expand: this.extendParams })
        .pipe(map((data: any) => {
          this.settings = data;
          return this.settings.BeltServiceCheckTypes;
        }));
    }

    getUserPermissions() {
        if (!this.settings) {
            this.settings = this.settingsProvider.getSettings();
        }

        return this.settings.UserPermissions || [];
    }

    getEnableEquipmentReturnScan() {
        if (!this.settings) {
            this.settings = this.settingsProvider.getSettings();
        }

        return this.settings.EnableEquipmentReturnScan;
    }

    // getEnableEquipmentUsageScan() {
    //     if (!this.settings) {
    //         this.settings = this.settingsProvider.getSettings();
    //     }

    //     return this.settings.EnableEquipmentUsageScan;
    // }

    getEnableEquipmentScanConfirmationSkip() {
        if (!this.settings) {
            this.settings = this.settingsProvider.getSettings();
        }

        return this.settings.EnableEquipmentScanConfirmationSkip;
    }

    getModules() {
        if (!this.settings) {
            this.settings = this.settingsProvider.getSettings();
        }

        return this.settings.Modules;
    }

    getCoPermissions() {
        if (!this.settings) {
            this.settings = this.settingsProvider.getSettings();
        }

        return this.settings.CoPermissions;
    }

    getPeriods() {
        if (!this.settings) {
            this.settings = this.settingsProvider.getSettings();
        }

        return this.settings.Periods;
    }

    getInspectionTypeApplicationScopes() {
      if (!this.settings) {
        this.settings = this.settingsProvider.getSettings();
      }
      return this.settings.InspectionTypeApplicationScopes;
    }

    getMaxImportanceLevel() {
        if (!this.settings) {
            this.settings = this.settingsProvider.getSettings();
        }

        return this.settings.MaxImportanceLevel;
    }

    getCompanySettsings(): CompanySettings {
        if (!this.settings) { this.settings = this.settingsProvider.getSettings(); }

        if (this.settings && this.settings.Company) { return new CompanySettings(this.settings.Company); }

        return new CompanySettings({});
    }

    getLanguagesSettings(): LanguagesSettings {
        if (!this.settings) { this.settings = this.settingsProvider.getSettings(); }

        if (this.settings && this.settings.Languages) { return new LanguagesSettings(this.settings.Languages); }
        return new LanguagesSettings(null);
    }

    getMaxParksCount(): number {
        if (!this.settings) {
            this.settings = this.settingsProvider.getSettings();
        }

        return this.settings.MaxParksCount;
    }

    setSettings(set: any) {
        this.settings = set;
        this.settingsHasChangedSubject.next();
    }

    userHasModule(moduleId): boolean {
        if (!this.settings) {
            this.settings = this.settingsProvider.getSettings();
        }

        const tmpModule = _.find(this.settings.Modules, { Id: moduleId });
        if (tmpModule) {
            return true;
        }

        return false;
    }

    userHasPermission(permission): boolean {
        if (!this.settings) {
            this.settings = this.settingsProvider.getSettings();
        }

        if (!this.settings.UserPermissions || this.settings.UserPermissions.indexOf(permission) < 0) {
            return false;
        }

        return true;
    }

    userHasRole(role): boolean {
        if (!this.settings) {
            this.settings = this.settingsProvider.getSettings();
        }

        if (this.settings.Roles.indexOf(role) < 0) {
            return false;
        }

        return true;

    }

    update(item: Settings): Observable<boolean> {
        this.settings = Object.assign(this.settings, item);
        return this.apiSvc.post('settings', item);
    }

    getDefaultTime() {
      let defaultTime: any = {};
      if (!this.settings) {
        this.settings = this.settingsProvider.getSettings();
      }

      if (this.settings.DailyReportEmployeStartTimeDefault) {
        defaultTime = {...defaultTime, ...{StartTime: this.settings.DailyReportEmployeStartTimeDefault}};
      }

      if (this.settings.DailyReportEmployeDurationDefault) {
        defaultTime = {...defaultTime, ...{Duration: this.settings.DailyReportEmployeDurationDefault}};
      }

      return defaultTime;
    }

}
