import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import {ApiService} from '../api.service';
import {MaintenanceComment} from '@app/core/model/belt/types';


@Injectable()
export class BeltServiceCommentService {
  private path = 'belt/service/comment?expand=Files,User';

  constructor(private apiSvc: ApiService) { }

  create(item: FormData) {
    return this.apiSvc.postFile(this.path, item)
      .pipe(map((data: MaintenanceComment) => data));
  }

}
