import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { FieldBase } from '../field';
import { UtilService } from '@app/core/service';

@Component({
  selector: 'app-df-group',
  templateUrl: './dynamic-form-group.component.html',
  styleUrls: ['./dynamic-form-group.component.scss']
})
export class DynamicFormGroupComponent {
  @Input() fields: FieldBase<any>[];
  @Input() formGroupName: string;
  @Input() form: FormGroup;
  constructor(public utilSvc: UtilService) {}

  dateKeyDown($event, type) {
    if (type === 'timer' && $event.code !== 'Backspace') {
      $event.preventDefault();
    }
  }
}
