import { InjectionToken } from '@angular/core';

import { ApiService } from './api.service';
import { RescueReport } from '../model';
import { BaseFeatureModuleService } from '@app/core/service/base-feature-module-service';

export const RescueReportService = new InjectionToken<BaseFeatureModuleService<RescueReport>>('RescueReport');

export function rescueReportSvcFactory(apiSvc: ApiService): BaseFeatureModuleService<RescueReport> {
    return new BaseFeatureModuleService<RescueReport>(apiSvc, 'report/rescue', {
        getItems: { expand: 'Park,Route,RouteComponent,DealtByUser',
                    fields: 'Id,ParkId,RouteId,DealtByUserId,Reason,DateTime,Park.Name,Route.Name,Route.Color,RouteComponent.Name,DealtByUser.*' },
        getItem: { expand: 'Park,Route,RouteComponent,Belt,DealtByUser,Ages' }
    });
}
