import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Inject
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

import { takeWhile } from 'rxjs/operators';

import { FieldBase } from './field';
import { FieldControlService } from './field-control.service';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { DatatablePageService } from '../service';
import { TranslateService } from '@ngx-translate/core';
import { WINDOW } from '../window-factory';

@Component({
  selector: 'app-df',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit, OnDestroy {
  @HostBinding('class')
  private classList = 'df';
  @Input()
  fields: FieldBase<any>[] = [];
  @Input()
  submitBtnTranslateKey = 'form.btnSave';
  @Input()
  actionBtns = true;
  @Input()
  deleteBtn = false;
  @Input()
  cancelBtn = true;
  @Input()
  printBtn = false;
  @Input()
  columnStructure: any;
  @Input()
  submitBtnActive = true;
  @Input()
  cancelUrl = '../list';
  @Output()
  dfSubmit = new EventEmitter();
  @Output()
  dfDelete = new EventEmitter();

  form: FormGroup;
  hasColumStructure = false;

  protected subscrAlive = true;

  constructor(
    private fcs: FieldControlService,
    public dtPageSvc: DatatablePageService,
    public translate: TranslateService,
    private dateTimeAdapter: DateTimeAdapter<any>,
    @Inject(WINDOW) public window: Window,
  ) {}

  ngOnInit(): void {
    // Set language for the datetimepicker
    this.dateTimeAdapter.setLocale(this.translate.currentLang);

    // Build form group
    this.form = this.fcs.toFormGroup(this.fields);

    // Subscribe for value changes
    this.form.valueChanges
      .pipe(takeWhile(() => this.subscrAlive))
      .subscribe(data => this.onValueChanged());

    this.hasColumStructure = this.columnStructure ? true : false;
  }

  onValueChanged(): void {
    this.updateErrors();
  }

  onDfSubmit(): void {
    if (!this.form.valid) {
      this.updateErrors(true);
    }
    this.dfSubmit.emit();
  }

  onDfDelete(): void {
    this.dfDelete.emit();
  }

  ngOnDestroy(): void {
    this.subscrAlive = false;
  }

  getFieldByKey(key: string): FieldBase<any> {
    const tmpField = this.fields.find(item => {
      return item.key === key;
    });
    return tmpField;
  }

  createFormFromOutsideField(fields: FieldBase<any>[]) {
    this.fields = fields;
    this.form = this.fcs.toFormGroup(this.fields);

    this.form.valueChanges
      .pipe(takeWhile(() => this.subscrAlive))
      .subscribe(data => this.onValueChanged());
  }

  updateErrors(wasSubmit = false) {
    if (!this.form) {
      return;
    }

    const form = this.form;
    let i = 0;
    const l = this.fields.length;

    // Loop through the fields
    for (; i < l; ++i) {
      const field = this.fields[i];
      this.updateFieldError(field, wasSubmit);
    }
  }

  updateFieldError(field: any, wasSubmit, prefix = ''): void {
    if (field.controlType === 'formgroup') {
      prefix = `${prefix}${field.key}.`;
      for (let i = 0; i < field.fields.length; ++i) {
        const iField = field.fields[i];
        this.updateFieldError(iField, wasSubmit, prefix);
      }
      return;
    }

    const control: AbstractControl = this.form.get(`${prefix}${field.key}`);
    // Clear error text
    if (control && control.valid) {
      field.error = '';
    }
    // Set up field error
    if (!wasSubmit && control && !control.dirty) {
      return;
    }

    if (control && !control.valid) {
      for (const key in control.errors) {
        if (control.errors.hasOwnProperty(key)) {
          const errorMessages = field.errorMessages;
          field.error = errorMessages[key] || errorMessages['default'];
          break;
        }
      }
    }
  }

  public markFormGroupDirty() {
    (<any>Object).values(this.form.controls).forEach(control => {
      control.markAsDirty();
    });
  }
}
